import type {
    ProductPageCampaignFragment,
    RootLayoutCampaignFragment,
} from '@server/gql/graphql';

import { campaignIsActive } from './campaignIsActive';

export function shouldShowCampaignBadge({
    campaign,
    priceType,
}: {
    campaign: RootLayoutCampaignFragment | ProductPageCampaignFragment | null;
    priceType?: string;
}) {
    if (!campaign) {
        return false;
    }

    const priceTypeIsCampaign = priceType === 'campaign';
    const showBadgeForAllProductsInCampaign =
        campaign.showBadgeForAllProductsInCampaign ?? false;
    const productIsPartOfCampaign =
        (priceTypeIsCampaign || showBadgeForAllProductsInCampaign) ?? false;

    return productIsPartOfCampaign && campaignIsActive(campaign);
}
