'use client';

import { useSiteInfo } from '@context/siteInfoContext';
import { useContext } from 'react';

import Stock from '../Stock/Stock';
import { ProductCardContext } from './ProductCardContext';

type Props = {
    className?: string;
};

const ProductCardStockStatus = ({ className }: Props) => {
    const { dictionary: globalDictionary } = useSiteInfo();
    const { hideStock, product } = useContext(ProductCardContext);

    if (hideStock) return null;

    return (
        <Stock
            dictionary={globalDictionary}
            item={product}
            type="plp"
            className={className}
        />
    );
};

ProductCardStockStatus.displayName = 'ProductCardStockStatus';

export default ProductCardStockStatus;
