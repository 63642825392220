import type { ProductPageCampaignFragment } from '@server/gql/graphql';

export default function getSortedCampaigns({
    campaigns,
    order,
}: {
    campaigns:
        | (ProductPageCampaignFragment | null | undefined)[]
        | null
        | undefined;
    order: (string | undefined)[];
}) {
    if (!campaigns?.length) {
        return undefined;
    }

    const priorityMap = new Map(order.map((id, index) => [id, index]));

    const sortedCampaigns = [...campaigns].sort(
        (a, b) =>
            (priorityMap.get(a?.sys.id) ?? Infinity) -
            (priorityMap.get(b?.sys.id) ?? Infinity),
    );

    return sortedCampaigns;
}
