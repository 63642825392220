import type {
    ProductPageCampaignFragment,
    RootLayoutCampaignFragment,
} from '@server/gql/graphql';

export const getMatchingCampaigns = ({
    campaigns,
    flags,
}: {
    campaigns:
        | (
              | ProductPageCampaignFragment
              | RootLayoutCampaignFragment
              | null
              | undefined
          )[]
        | null;
    flags: string[];
}) => {
    if (!campaigns?.length || !flags?.length) {
        return undefined;
    }

    return campaigns.filter((campaign) => {
        return (
            campaign !== undefined &&
            campaign !== null &&
            campaign.commerceId &&
            flags.includes(campaign.commerceId)
        );
    });
};
