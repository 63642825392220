'use client';

import { useSiteInfo } from '@context/siteInfoContext';
import type { ComponentProps, ReactNode } from 'react';
import { useContext, useMemo } from 'react';

import { envConfig } from '@/config/env';
import { cn } from '@/lib/utils';
import { getMatchingCampaigns } from '@/utils/product/getMatchingCampaigns';
import getSortedCampaigns from '@/utils/product/getSortedCampaigns';
import { shouldShowCampaignBadge } from '@/utils/product/shouldShowCampaignBadge';

import ProductCardBadge from './ProductCardBadge';
import { ProductCardContext } from './ProductCardContext';
import ProductCardFavoriteButton from './ProductCardFavoriteButton';
import ProductCardStockStatus from './ProductCardStockStatus';

type ProductCardBadgesProps = {
    children?: ReactNode;
};

const ProductCardBadges = ({ children }: ProductCardBadgesProps) => {
    const { product, hoverImage, hideHoverImage, globalDictionary } =
        useContext(ProductCardContext);
    const { campaigns, campaignPriorityCollection } = useSiteInfo();
    const productBadge = useMemo(() => {
        if (!product) {
            return undefined;
        }

        const campaignsPriorityOrder =
            campaignPriorityCollection?.items.map(
                (campaign) => campaign?.sys.id,
            ) ?? [];

        const matchingCampaigns = getMatchingCampaigns({
            campaigns: campaigns ?? [],
            flags: product.flags ?? [],
        });

        const sortedCampaigns = getSortedCampaigns({
            campaigns: matchingCampaigns,
            order: campaignsPriorityOrder,
        });

        return {
            // Only show the first campaign badge after sorting them based on priority
            campaigns: [sortedCampaigns?.[0]],
            isNew: product.flags?.includes('news'),
            isLimited: product.flags?.includes('limited_edition'),
        };
    }, [product, campaigns, campaignPriorityCollection]);

    const badgeClassNameOnHover = () => {
        // Do not animate badges on hover if there is no hover image
        if (!hoverImage || hideHoverImage) return null;

        return 'transition-opacity group-hover:opacity-0';
    };

    return (
        <>
            <ProductCardBadge
                x="start"
                y="start"
                className="flex flex-wrap content-start items-start px-4 py-3 pr-10 leading-[2.1]">
                <span>
                    {productBadge?.campaigns?.map(
                        (campaign) =>
                            campaign?.campaignBadge &&
                            shouldShowCampaignBadge({
                                priceType: product?.priceType,
                                campaign,
                            }) && (
                                <ProductCardBadgeLabel
                                    key={campaign.campaignBadge}>
                                    {campaign.campaignBadge}
                                </ProductCardBadgeLabel>
                            ),
                    )}
                    {productBadge?.isNew && (
                        <ProductCardBadgeLabel>
                            {globalDictionary?.new || 'New'}
                        </ProductCardBadgeLabel>
                    )}
                </span>
            </ProductCardBadge>
            <ProductCardBadge
                y="end"
                className={cn(
                    'px-4 py-3',
                    badgeClassNameOnHover(),
                    'flex w-full',
                    productBadge?.isLimited && 'mb-3',
                )}>
                <ProductCardStockStatus className="mt-0" />
                {product?.hasVariants && (
                    <span className="ml-auto">
                        {globalDictionary?.moreVariants}
                    </span>
                )}
            </ProductCardBadge>
            {envConfig.NEXT_PUBLIC_NORDICS_FEATURE_FLAG === 'true' && (
                <ProductCardBadge y="start" x="end">
                    <ProductCardFavoriteButton />
                </ProductCardBadge>
            )}
            {productBadge?.isLimited && (
                <ProductCardBadge className="inset-x-0 bottom-0 bg-primary py-0.5 text-center font-medium text-black">
                    {globalDictionary?.limitedEdition || 'NOGA Exclusives'}
                </ProductCardBadge>
            )}
            {children}
        </>
    );
};

function ProductCardBadgeLabel({
    children,
    className,
    ...props
}: ComponentProps<'span'>) {
    return (
        <span
            className={cn(
                // https://css-tricks.com/multi-line-padded-text/#aa-fabien-doirons-box-shadow-method
                'mr-3 inline bg-primary decoration-clone py-1 text-xxs font-medium tracking-wide text-black shadow-[0.5em_0_0_hsl(var(--primary-500)),-0.5em_0_0_hsl(var(--primary-500))] last-of-type:mr-0',
                className,
            )}
            {...props}>
            {children}
        </span>
    );
}

ProductCardBadges.displayName = 'ProductCardBadges';

export default ProductCardBadges;
